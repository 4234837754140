
import { Component, Prop, Vue } from "vue-property-decorator";
import InviteEmail from "@/invite/InviteEmail.vue";
import InviteLink from "@/invite/InviteLink.vue";
import InviteFacebook from "@/invite/InviteFacebook.vue";
import InviteGmailContainer from "@/invite/InviteGmailContainer.vue";

import store from "@/store";

@Component({
  components: {
    InviteLink,
    InviteEmail,
    InviteFacebook,
    InviteGmailContainer
  }
})
export default class Invite extends Vue {
  @Prop({default: true}) showHeader!: boolean;

  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Invite"));
  }
}
