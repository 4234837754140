
import { Component, Prop, Vue } from "vue-property-decorator";
import InviteGmail from "./InviteGmail.vue";

@Component({
    components: {
        InviteGmail
    }
})
export default class InviteGmailContainer extends Vue {
  isLoading = false;
  email = "";

  importFromGoogle() {
      (this.$refs.inviteGmail as InviteGmail).open();
  }
}
