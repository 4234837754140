
import { Component, Vue } from "vue-property-decorator";
import store from "../store/index";
import { callInvite } from "../serviceClients/invitationWebServices";
import { pushError } from "@/core";

@Component({
    components: {
    }
})
export default class InviteEmail extends Vue {
  isLoading = false;
  email = "";

  onSubmit() {
    this.isLoading = true;
    callInvite(store.getters.module.user!.id, [ this.email ])
      .then(() => {
        this.$toasted.success(this.$t("Invitation email sent to") + " " + this.email, {
          duration: 2000,
        });
        this.email = "";
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while sending invitation") });
      });
  }
}
