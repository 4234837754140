declare const gapi: any;

const clientId = '23742827354-ou530dq74r93baq6s55up9mmt4bnuend.apps.googleusercontent.com';
const clientSecret = 'ViXhi_TuVdQlqjBPB1jzxSnA';
const apiKey = 'AIzaSyAnmYIN0mDrZQ17cm-a7VQfsiMRcn0HGuc';
// Array of API discovery doc URLs for APIs used by the quickstart
const discoveryDocs = ["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const scopes = "https://www.googleapis.com/auth/contacts.other.readonly";

interface GapiEmailAddress {
    value: string;
    metadata: {
        primary: boolean;
    }
}

interface GapiName {
    displayName: string;
    metadata: {
        primary: boolean;
    }
}

interface GapiConnection {
    emailAddresses: GapiEmailAddress[];
    names: GapiName[];
    etag: string;
}

interface GapiResponse {
    result: {
        otherContacts: GapiConnection[]
    }
}

export interface GmailContact {
    email: string;
    name: string;
    id: string;
}

function getEmails(response: GapiResponse) : GmailContact[] {
    const connections = response.result.otherContacts;
    if(!connections) {
        return [];
    }
    return connections
        .filter(c => !!c.emailAddresses)
        .map(c => {
            const email = c.emailAddresses
                .filter(m => m.metadata.primary)
                .map(m => m.value)[0];
            const name = c.names ? c.names
                .filter(m => m.metadata.primary)
                    .map(m => m.displayName)[0]
                : undefined;
            return {
                email,
                name: name || email,
                id: c.etag + email
            }
        });
}

function initClient() {
    return gapi.client.init({
      apiKey,
      clientId,
      discoveryDocs,
      scope: scopes
    }).then(() => {
      return gapi.auth2.getAuthInstance().signIn()
        .then(() => {
          return gapi.client.people.otherContacts.list({
            'pageSize': 1000,
            'readMask': 'names,emailAddresses',
          }).then((response: GapiResponse) => {
            return getEmails(response);
          });
        });
    })
  }

export async function loadGmailContacts() {
    let resolveInitCalled = () => { /* noop */ };
    const initCalledPromise = new Promise((resolve, reject) => {
        resolveInitCalled = resolve;
    });

    let initClientResult: Promise<any>;

    gapi.load('client:auth2', () => {
        initClientResult = initClient();
        resolveInitCalled();
    });
    await initCalledPromise;

    return await initClientResult!;
}