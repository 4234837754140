
import { Component, Prop, Vue } from "vue-property-decorator";
import { getBaseUrl, getFacebookClientId } from "../helpers/settingsHelper";
import store from "../store/index";

declare const FB: any;

@Component({
    components: {
    }
})
export default class InviteFacebook extends Vue {
  get link() {
    return `${getBaseUrl()}/register/${encodeURI(store.getters.module.user!.id)}`;
  }

  inviteFacebook() {
      FB.init({
        appId            : getFacebookClientId(),
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v9.0'
      });
      FB.ui({
        display: 'popup',
        method: 'share',
        href: this.link,
      }, (response: any) => {});
    }
}
