import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/invitation`;


export function callInvite(userId: string, emails: string[]) {
    return axios.default.post<void>(`${url}/${userId}`, { data: emails });
}

export function click(inviteId: string) {
    return axios.default.put<void>(`${url}/${inviteId}/click`);
}