
import { Component, Prop, Vue } from "vue-property-decorator";
import { getBaseUrl } from "../helpers/settingsHelper";
import store from "../store/index";

declare const FB: any;

@Component({
    components: {
    }
})
export default class InviteLink extends Vue {
  get link() {
    return `${getBaseUrl()}/register/${encodeURI(store.getters.module.user!.id)}`;
  }

  copyLink() {
    var inputEl = this.$refs.linkInput as HTMLInputElement;
    inputEl.select();
    inputEl.setSelectionRange(0, 99999);
    document.execCommand("copy");

    this.$toasted.success(this.$t("Invitation link copied to clipboard").toString(), {
        duration: 2000,
      });
  }

  linkClicked(event: Event) {
    var inputEl = event.target as HTMLInputElement;
    inputEl.select();
    inputEl.setSelectionRange(0, 99999);
  }
}
