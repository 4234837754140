
import { pushError } from "@/core";
import { Component, Prop, Vue } from "vue-property-decorator";
import { callInvite } from "../serviceClients/invitationWebServices";
import store from "../store";
import { GmailContact, loadGmailContacts } from "./gmailContactsClient";

interface SelectableContact extends GmailContact {
    isSelected: boolean;
}

@Component({})
export default class InviteGmail extends Vue {
    contacts: SelectableContact[] = [];
    filter: string = "";
    isLoading = false;
    email = "";
    showSelectAll = false;

    get filteredContacts() {
        return this.contacts.filter(c => !this.filter || 
            (c.email + c.name).toLowerCase().indexOf(this.filter.toLowerCase()) !== -1)
    }

    open() {
        this.showSelectAll = false;
        loadGmailContacts()
            .then((response) => {
                this.contacts = response.map((c: GmailContact) => {
                    return {
                        isSelected: true,
                        name: c.name,
                        id: c.id,
                        email: c.email
                    };
                });
                this.show();
            })
            .catch(error => {
                if (error.error) {
                    if(error.error = "popup_closed_by_user") {
                        pushError("Cancelled by user")
                    } else {
                        pushError(error.error);
                    }
                    return;
                }
                pushError(error);
            });
    }

    show() {
        (this.$refs['modal'] as any).show();
    }

    hide() {
        (this.$refs['modal'] as any).hide();
    }

    invite() {
        this.isLoading = true;
        const selectedEmails = this.contacts
            .filter(c => c.isSelected)
            .map(c => c.email);
        callInvite(store.getters.module.user!.id, selectedEmails)
        .then(() => {
            this.$toasted.success(`Invitation email sent`, {
            duration: 2000,
            });
            this.hide();
        })
        .finally(() => {
            this.isLoading = false;
        })
        .catch((error) => {
            pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while logging in") })
        });
    }

    selectAll() {
        this.showSelectAll = false;
        for(let contact of this.contacts) {
            contact.isSelected = true;
        }
    }

    deselectAll() {
        this.showSelectAll = true;
        for(let contact of this.contacts) {
            contact.isSelected = false;
        }
    }
}
